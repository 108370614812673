import React from "react"
import Img from "gatsby-image"

const Postcard= props => {
  return (
    <React.Fragment>
        <div className="img-container" key={props.index}>
          <div className="text">
          <h2
            dangerouslySetInnerHTML={{
              __html: props.item.node.frontmatter.title,
            }}
          ></h2>
          <p>{props.item.node.frontmatter.date}</p>
          </div>

          <div className="image">
            <Img
              fluid={{
                ...props.item.node.frontmatter.featured_image.childImageSharp
                  .fluid,
                aspectRatio: 6 / 4,
              }}
              alt=""
              title=""
            />
          </div>
        </div>
      <style >
        {`
          .img-container {
            position: relative;
            width: 100%;
            background: #eee;
            border-radius: 5px;
            border: 8px solid ghostwhite;
            overflow: hidden;
            z-index: 1;
            margin-bottom: 24px;
          }

          .img-container::before {
            content: "";
            display: block;
            margin-top: 66.666%;
            z-index: 1;
          }

          .img-container .image {
            top: 0;
            display: block;
            position: absolute;
            width: 100%;
            height: auto;
            margin: 0 !important;
            border-radius: 5px;
            border: 1px solid gray;
            transition: 0.2s ease-in-out;
            z-index: 2;

          }
          .img-container::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              to top,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0) 60%
            );
            z-index: 2;
          }

          .img-container .text{

            position: absolute;
            left: 16px;
            bottom: 16px;
            z-index: 3;
            margin-bottom: 0;
          }

          .text {
            color: white;
          }

          .img-container h2 {
            font-size: 60px;
            text-transform: uppercase;
            font-weight: 200;
            color: white;
            text-shadow: 0px 1px black;
            margin-bottom: 0px;
          }

          .img-container h2 strong {
            font-weight: bold;
          }
        `}
      </style>
    </React.Fragment>
  )
}

export default Postcard 
